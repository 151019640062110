export function hideElement(element) {
  element.style.display = 'none';
}

export function showElement(element) {
  element.style.display = 'block';
}

export function setElementVisibility(element, isVisible) {
  if (isVisible) {
    showElement(element);
  } else {
    hideElement(element);
  }
}

export function findElementByClass(name) {
  var element = document.querySelector(`.` + name);
  return element;
}

export function findChildElementByClass(element, name) {
  var child_element = element.querySelector(`.` + name);
  return child_element;
}
export function findChildElementById(element, name) {
  var child_element = element.querySelector( name);
  return child_element;
}
export function findChildElementByAttribute(element, name) {
  var child_element = element.querySelector(`[` + name + ']');
  return child_element;
}

// hide or show an element that is 'flex' when visible

export function toggleFlexDisplayVisibility(element, isVisible) {
  if (isVisible) {
    element.style.display = 'flex';
  } else {
    element.style.display = 'none';
  }
}