import { Modal } from './SOL_Modal';
import { ModalContext } from './SOL_Interface';
import * as html_functions from './html_functions';

export class HelpModal extends Modal {
  constructor() {
    super('help');
  }

  setModalContext(modal_context) {
    super.setModalContext(modal_context);

    var exit_button = html_functions.findChildElementByClass(this.modal, 'exit-game');
    var close_button = html_functions.findChildElementByAttribute(this.modal, 'data-close');

    var show_exit_button = this.modal_context == ModalContext.from_game_center;
    html_functions.setElementVisibility(exit_button, show_exit_button);

    var show_close_button = this.modal_context == ModalContext.from_solitaire;
    html_functions.setElementVisibility(close_button, show_close_button);
  }
}
