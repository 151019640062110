import { Modal } from './SOL_Modal';
import { ModalContext } from './SOL_Interface';
import * as html_functions from './html_functions';

export class NewGameModal extends Modal {
  constructor() {
    super('newgame');
    this.play_button = document.getElementById(`play-game-button`);
    this.play_button_click=(e) => {
      this.startNewGame();
    };
    this.play_button.addEventListener('click', this.play_button_click);
  }

  setModalContext(modal_context) {
    super.setModalContext(modal_context);
  }

  close() {
    super.close();
    this.play_button.removeEventListener('click',this.play_button_click);
  }
}
