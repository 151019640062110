import * as html_functions from './html_functions';

export const timerAndPointsDiv = document.getElementById('timer-and-points');
export const navBar=document.querySelector('.navbar');

export function setTimerAndPointsVisibility(isVisible) {
  html_functions.toggleFlexDisplayVisibility(timerAndPointsDiv, isVisible);
}

export function setNavbarVisibility(isVisible) {
  html_functions.toggleFlexDisplayVisibility(navBar, isVisible);
}

export const IconImage = {
  Normal: 0,
  Hilight: 1,
  Inactive: 2,
};

export const IconNamingScheme = {
  lit: 0,
  hover: 1,
};

export const ModalContext = {
  from_game_center: 0,
  from_solitaire: 1,
};

export const IconImageLookup = [
  ['', 'lit', 'dim'],
  ['', 'hover', 'inactive'],
];

export const ImageFileFormatExtension = '.svg';
