/*
 *  modal.js
 *  Solitaire
 *
 *  Created by Kim Moran on Aug 18, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  NavBar modal has a button that can be clicked on the side of the screen to open and close a modal dialog window
 *  Modals class is a group of Modal objects, helps coordiate modal dialog behavior
 */
import {IconImage, IconNamingScheme,ModalContext,IconImageLookup,ImageFileFormatExtension} from './SOL_Interface';

export class Modal {
  constructor(name, icon_image_name = undefined, icon_naming_scheme = IconNamingScheme.lit) {
    this.isOpen = false;
    this.name = name + 'Modal';
    this.modal = document.querySelector(`.${name}-modal`);
    this.target = document.querySelector(`[data-toggle="${name}-modal"]`);
    this.closeModal = this.modal.querySelectorAll('[data-close]');
    this.setModalContext(ModalContext.from_solitaire);

    if (icon_image_name == undefined) {
      icon_image_name = name;
    }

    this.icon_images = [
      `./assets/common/icons/${icon_image_name}.svg`,
      `./assets/common/icons/${icon_image_name}-${IconImageLookup[icon_naming_scheme][IconImage.Hilight]}${ImageFileFormatExtension}`,
    ];

    this.icon = document.querySelector(`.${name}-icon`);
    this.setIconImage();

    var close = this.modal.querySelector('.close-icon');

    if (!close) {
      close = this.modal.querySelector('.closed-icon');
    }

    if (close) {
      close.src = './assets/common/icons/closeIcon.svg';
    }

    /* ---------------------------------------------------------------- */

    this.addEventListeners();
  }
  setModalContext(modal_context){
    this.modal_context=modal_context;
  }

  setIconImage(imageIndex = IconImage.Normal) {
    if (this.icon) {
      this.icon.src = this.icon_images[imageIndex];
    }
  }

  addEventListeners() {
    this.modal.addEventListener('click', (e) => {
      // if (e.target == this.modal) {
      //   return this.close();
      // }
      e.stopPropagation();
    });
    this.modal.addEventListener('dblclick', (e) => {
      // if (e.target == this.modal) {
      //   return this.close();
      // }
      e.stopPropagation();
    });
    this.modal.addEventListener('mousedown', (e) => {
      e.stopPropagation();
    });
    this.modal.addEventListener('mouseup', (e) => {
      e.stopPropagation();
    });
    this.closeModal.forEach((item) => {
      item.addEventListener('click', (e) => {
        return this.close();
      });
    });

    if(this.target){
      this.target.addEventListener('mousedown', (e) => {
        e.stopPropagation();
      });
      this.target.addEventListener('mouseup', (e) => {
        e.stopPropagation();
      });
      this.target.addEventListener('click', (e) => {
        this.toggle_open();
        e.stopPropagation();
      });
    }
  }

  toggle_open() {
    if (this.isOpen) {
      return this.close();
    } else {
      return this.open();
    }
  }

  open() {
    if (this.isOpen) {
      return;
    }

    if (this.modals) {
      this.modals.closeAll();
    }

    this.modal.classList.add('show-modal');
    this.isOpen = true;
    this.setIconImage(IconImage.Hilight);

    setTimeout(() => {
      this.animateIn();
    }, 20);
  }

  close() {
    if (!this.isOpen) {
      return;
    }

    this.animateOut();
    this.isOpen = false;
    this.setIconImage(IconImage.Normal);

    setTimeout(() => {
      this.modal.classList.remove('show-modal');
    }, 20);
  }

  animateIn() {
    this.modal.classList.add('animate-modal');
  }

  animateOut() {
    this.modal.classList.remove('animate-modal');
  }
}

