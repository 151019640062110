/*
 *  SOL_Object.js
 *  Solitaire
 *
 *  Created by Kim Moran on Sept 5, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Global solitaire object
 */
/* ---------------------------------------------------------------- */

// Declare a global SOL_OBJECT for use throughout the code and for easy transmission of the object to the client or the server

export function SOL_Object() {

  return {
    // each game will need a UUID. The SOL_OBJECT holds all data to render a game.

    uuid: '',                       // the uuid is created after it gets saved to the server

    client: {
      seed: [],
      cards: [],
      placement: {
        stock: [],
        waste: [],
        foundation: {               // 4 separate placements for each foundation
          fnd1: [],
          fnd2: [],
          fnd3: [],
          fnd4: [],
        },
        tableau: {                  // 7 separate placements for each tableau
          tab1: [],
          tab2: [],
          tab3: [],
          tab4: [],
          tab5: [],
          tab6: [],
          tab7: [],
        },
      },
      layout: {
        width: 0,                   // screen width
        height: 0,                  // screen height
        cardSpace: 0,               // total width card takes up with padding. Needed for x and y points of tableau/foundation columns
        cardXpadding: 0,                // x and y gap between cards on the columns in the tableau. Subjectively set to 10% km 9/11/22
        cardYpadding: 0,
        cardWidth: 0,               // just the card width, no padding
        cardHeight: 0,
        stockXpos: 0,               // starting x and y points for the stock
        stockYpos: 0,
        wasteXpos: 0,               // starting x and y points for waste
        wasteYpos: 0,
        foundationXpos: 0,          // starting x and y points for the 1st column in the foundation. Y always stays the same, only top card is drawn to the canvas.
        foundationYpos: 0,
        tableauXpos: 0,             // starting x and y points for the 1st column in the tableau. Y changes based on number of cards in array.
        tableauYpos: 0,
      },
      gameTick: 0,
      assets: null,
      draggingCard: null,            // draggingCard is one card and setting it here it can now be used/set from multiple modules to avoid spaghetti code
      hoveredCard: null,             // hoveredCard is one card we are hovering over with a cursor
      landingCard: null,
      emptyStockSlot: {},
      emptyWasteSlot: {},            // landingCard is here so we can use in multiple modules and to help score the game
      emptyFoundationSlots: [],
      emptyTableauSlots: [],
      mouseX: 0,
      mouseY: 0,
      deltaX: 0,                    // offset to cursor before dragging a card
      deltaY: 0,
      targetX: 0,
      targetY: 0,
      gamecount: 0,
      gameState: 0,
      GAMESTATE: {
        NONE: 0,
        INITIALIZING: 1,
        PLAYING: 2,
        LOST: 3,
        WON: 4,
        SCORESCREEN: 5,
        COMPLETE: 6
      },
      timerInterval: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
      points: 0,
      moves: 0,
      secondsPlayed: 0,
      secondsBest: 0,
      wins: 0,
      losses: 0,
      animSpeed: 45,
      flipSpeed: 1,
      winSpeed: 9,
      winDelay: 0,
      dealSpeed: 22,
      animate: false,
      cardsDealt: false, // becomes true after card placements are computed
      gameMode: 0,
      GAMEMODE: {
        STANDARD: 0,
        KLONDIKE: 1
      },
      wasteCardCount: 3,  // waste card count always starts at three at the beginning of the game. The top waste card is the farthest to the right and accessible first.
      deckFlip: 0,  // count the times the player has gone through the deck
      playerDevice: 'desktop', // desktop or mobile
      cardChoice: 'desktop', // change to standard during feature add on.
      station: 'XTRA', // default to generic station if none provided in call letters from AWE.
    },
  };
}
