/*
 *  SOL_Update.js
 *  Solitaire
 *
 *  Created by Kim Moran on Sept 8, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Updates SOL_Object
 *
 *  // NOTES
 *  // For now, tracking card movements and updating SOL_Objects for the next click.
 */
/* ---------------------------------------------------------------- */

// updating card properties and calling a new render based on a selected card

export function SOL_Update(client, SOL_Score, SOL_Animation) {

  /* ---------------------------------------------------------------- */

  // provide game updates once we re-render the screen

  this.updateMovesAndPoints = function () {
    if (client.gameState == client.GAMESTATE.WON) {
      return;
    }
    this.updateStats(client);
    this.updateStatsModal();

  },

  this.convertSeconds = function (secondsPlayed) {

    let minutes = Math.floor(secondsPlayed / 60);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    let seconds = Math.floor(secondsPlayed % 60);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    // if we have more than an hour, account for it
    if (secondsPlayed > 3600) {
      let hours = Math.floor(minutes / 60);
      if (hours < 10) {
        hours = `0${hours}`;
      }
      minutes = Math.floor(minutes % 60);
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return `${minutes}:${seconds}`;
    }

  },

  this.toPercentage = function (number) {
    // protect from NaN
    if (isNaN(number)) {
      number = 0;
    }

    return (Math.floor(number * 100)).toString() + '%';
  },

  this.resetCurrentStats = function () {
    SOL_Score.createLocalScoreObject();

  };
  /* ---------------------------------------------------------------- */

  // provides stats updates upon win or loss, restart or new game

  this.updateStats = function (client) {
    if (client.gameState != client.GAMESTATE.WON) {
      client.points = SOL_Score.calculateScore();
    }

    let { gameCount, pointsCurrent, pointsBest, pointsAverage, pointsTotal, timeCurrent, secondsBest, secondsAverage, secondsTotal, secondsPlayed, timeBest, timeAverage, timeTotal, movesCurrent, movesBest, movesAverage, movesTotal, wins, winPercentage, winPointsAverage, winPointsTotal, winStreakCount, topWinStreak, winStreak, winTimeBest, winTimeAverage, winTimeTotal, winMovesCurrent, winMovesBest, winMovesAverage, winMovesTotal, winSecondsBest, winSecondsAverage, winSecondsTotal, losses, lossPercentage } = JSON.parse(localStorage.getItem('stats'));

    // POINTS math
    pointsCurrent = Math.ceil(client.points);

    // TIME math
    secondsPlayed = client.secondsPlayed;
    timeCurrent = this.convertSeconds(secondsPlayed);
    secondsBest = client.secondsBest;

    // MOVES math

    movesCurrent = client.moves;

    if (client.gameState == client.GAMESTATE.WON) {
      // POINTS math
      gameCount++;
      client.wins++;
      wins = client.wins;
      winStreak = true;
      winStreakCount++;

      if (winStreakCount > topWinStreak) {
        topWinStreak = winStreakCount;
      }

      // UPDATE MOVES, TIME regardless of game state.
      pointsTotal += pointsCurrent;
      movesTotal += movesCurrent;
      secondsTotal += secondsPlayed;
      timeTotal = this.convertSeconds(secondsTotal);

      //totals stats
      winPointsTotal += pointsCurrent;
      winMovesTotal += movesCurrent;
      winSecondsTotal += secondsPlayed;
      winTimeTotal = this.convertSeconds(winSecondsTotal);

      //average stats
      winPointsAverage = Math.floor(winPointsTotal / wins);
      winMovesAverage = Math.trunc(winMovesTotal / wins);
      winSecondsAverage = Math.trunc(winSecondsTotal / wins);
      winTimeAverage = this.convertSeconds(winSecondsAverage);

      // only non-winning average needed.
      timeAverage = this.convertSeconds(secondsTotal / gameCount);

      // best stats
      if (winSecondsBest == 0) {
        winTimeBest = timeCurrent;
        winSecondsBest = secondsPlayed;
      } else {
        if (winSecondsBest > secondsPlayed) {
          winMovesBest = movesCurrent; // Only update the best moves for a win if we have a new lower win time. The best moves should only change if the best win time changes.
        }
        winSecondsBest = Math.min(winSecondsBest, secondsPlayed);
        winTimeBest = this.convertSeconds(winSecondsBest);
      }

      // timeBest = Math.min(timeBest, timeCurrent);
      pointsBest = Math.max(pointsBest, pointsCurrent);

      if (movesBest == 0) {
        movesBest = movesCurrent;
      } else {
        movesBest = Math.min(movesBest, movesCurrent);
      }

      // UPDATES IF GAME LOST (WHEN NEW GAME ACTIVATED )
    } else if (client.gameState == client.GAMESTATE.LOST) {
      gameCount++;
      client.losses++;
      losses++;
      winStreak = false;
      topWinStreak = Math.max(winStreakCount, topWinStreak);
      winStreakCount = 0;

      // update our accumulators
      pointsTotal += pointsCurrent;
      movesTotal += movesCurrent;
      secondsTotal += secondsPlayed;
      timeTotal = this.convertSeconds(secondsTotal);

      // only non-winning average needed.
      timeAverage = this.convertSeconds(secondsTotal / gameCount);

    // eslint-disable-next-line no-empty
    } else { }


    // UPDATE LOCAL STORAGE STATS OBJECT NOW

    stats.gameCount = gameCount;
    if (/debug/.test(location.search)) {
      console.debug(
        'stats.gameCount: ' + stats.gameCount
      );
    }

    //    WINS
    stats.wins = wins;
    if (/debug/.test(location.search)) {
      console.debug(
        'stats.wins', stats.wins
      );
    }

    stats.winPercentage = this.toPercentage(stats.wins / stats.gameCount);
    stats.winStreakCount = winStreakCount;
    stats.winStreak = winStreak;
    stats.topWinStreak = topWinStreak;
    stats.winSecondsBest = winSecondsBest;
    stats.winSecondsAverage = winSecondsAverage;
    stats.winSecondsTotal = winSecondsTotal;
    stats.winTimeBest = winTimeBest;
    stats.winTimeAverage = winTimeAverage;
    stats.winTimeTotal = winTimeTotal;
    stats.winMovesBest = winMovesBest;
    stats.winMovesAverage = winMovesAverage;
    stats.winMovesTotal = winMovesTotal;
    stats.winMovesCurrent = winMovesCurrent;

    //    MOVES

    stats.movesCurrent = movesCurrent;
    stats.movesBest = movesBest;
    stats.movesAverage = movesAverage;
    stats.movesTotal = movesTotal;


    //    LOSSES
    stats.losses = losses;
    if (/debug/.test(location.search)) {
      console.debug(
        'stats.losses', stats.losses
      );
    }
    if (stats.gameCount == 0) {
      stats.lossPercentage = '0%';
    } else {
      stats.lossPercentage = 100 - (parseInt(stats.winPercentage)).toString() + '%';
    }
    // if loss percentage comes back as NaN or undefined, set it at 0%.
    if (isNaN(stats.wins / stats.gameCount)) {
      stats.lossPercentage = '0%';
    }
    //    POINTS
    stats.pointsCurrent = pointsCurrent;
    stats.pointsTotal = pointsTotal;
    stats.pointsBest = pointsBest;
    stats.pointsAverage = pointsAverage;

    //    TIME
    stats.timeCurrent = timeCurrent;
    stats.secondsBest = secondsBest;
    stats.secondsAverage = secondsAverage;
    stats.secondsTotal = secondsTotal;
    stats.secondsPlayed = secondsPlayed;
    stats.timeBest = this.convertSeconds(stats.secondsBest);
    stats.timeAverage = timeAverage;
    stats.timeTotal = timeTotal; // KEEP - win or loss

    localStorage.setItem('stats', JSON.stringify(stats));

  },
  /* ---------------------------------------------------------------- */


  this.updateStatsModal = function () {

    // const stats = JSON.parse(localStorage.getItem('stats'));

    const { timeTotal, pointsTotal, timeAverage, winTimeAverage, winTimeBest, winStreakCount, wins, gameCount, losses, lossPercentage, winPercentage, pointsCurrent } = JSON.parse(localStorage.getItem('stats'));

    // if (stats.wins / stats.losses == 0
    document.querySelector('.time-total').innerHTML = timeTotal; // have, need to verify
    document.querySelector('.points-total').innerHTML = pointsTotal; // have, need to verify
    document.querySelector('.time-average').innerHTML = timeAverage; // have, need to verify
    document.querySelector('.win-time-average').innerHTML = winTimeAverage; // need to create
    document.querySelector('.win-time-best').innerHTML = winTimeBest;// need to create
    document.querySelector('.top-win-streak').innerHTML = `${winStreakCount} (Longest: ${stats.topWinStreak})`; // have win streak count, need to create the top winstreak.

    document.querySelector('.won-total').innerHTML = `WON ${wins}`;
    document.querySelector('.total-games').innerHTML = `${gameCount} Games Played`;
    document.querySelector('.lost-total').innerHTML = `${losses} LOST`;

    document.querySelector('.red-percentage').innerHTML = lossPercentage;
    document.querySelector('.green-percentage').innerHTML = winPercentage;
    document.querySelector('.points').innerHTML = `${pointsCurrent} Points`;

    this.updateColorBar(losses, gameCount, lossPercentage, winPercentage);

    return true;
  };

  /* ---------------------------------------------------------------- */

  // update the color bar stats gradient based on the win loss ratio.

  this.updateColorBar = function (losses, gameCount, lossPercentage, winPercentage) {

    let winGradient;
    let lossGradient = Math.abs(100 - ((losses / gameCount) * 100));
    // split for readability, round the % and convert to string for gradient.
    lossGradient = Math.trunc(lossGradient).toString() + '%';

    if (lossPercentage == '100%') {
      winGradient = '5%';
      lossGradient = '10%';
    } else if (winPercentage == '100%') {
      winGradient = '95%';
      lossGradient = '10%';
    } else if (lossPercentage == '0%' && winPercentage == '0%') {
      winGradient = '50%';
      lossGradient = '50%';
    } else {
      (winGradient = winPercentage);
    }

    document.querySelector('.color-bar').style.background = `linear-gradient(270deg, #037D00 0%, #04B000 ${winGradient}, #FFBD13 ${lossGradient}, #E3005F 100%)`;
  };


  /* ---------------------------------------------------------------- */

  // DO NOT DELETE - change the icon for 'undo' based on moves and click event. Could do in css but if no moves we don't want it changing.

  // const undoIcon = './assets/common/icons/undo.svg';
  // const undoIconLit = './assets/common/icons/undo-lit.svg';
  // const undoIconDim = './assets/common/icons/undo-dim.svg';

  // this.undoIconUpdate = function (stackLength, undo, clicked = false) {
  //   if (stackLength < 2) {
  //     document.getElementById('undo').style.pointerEvents = 'none';
  //   } else {
  //     document.getElementById('undo').style.pointerEvents = 'auto';

  //   }

  //   if (stackLength > 1 && clicked) {
  //     undo.src = undoIconLit;
  //     setTimeout(() => {
  //       undo.src = undoIcon;                                 // make the button light up after being pressed
  //     }, 200);
  //   } else {
  //     if (stackLength < 2) {
  //       document.getElementById('undo').style.pointerEvents = 'none';
  //       undo.src = undoIconDim;
  //     } else {
  //       undo.src = undoIcon;
  //     }
  //   }
  //   return undo;
  // };

  /* ---------------------------------------------------------------- */

  // Update the icon for the new game button on click

  this.newGameIconUpdate = function (newButton) {
    console.log('newGameIconUpdate reached');
    newButton.src = './assets/common/icons/newgame-lit.svg';
    setTimeout(() => {
      newButton.src = './assets/common/icons/newgame.svg';
    }, 200);
  };

}


