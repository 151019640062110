import * as html_functions from './html_functions';
import {ModalContext} from './SOL_Interface';

export class Modals {
  constructor() {
    this.items = [];
    this.modal_context = ModalContext.from_solitaire;
  }

  addModal(modal) {
    this.items.push(modal);
    modal.modals = this;
    return modal;
  }

  closeAll() {
    this.items.forEach((element) => {
      element.close();
    });
  }

  OnNonModalClick(){
    if(this.modal_context==ModalContext.from_solitaire){
      this.closeAll();
    }
  }

  anyOpen() {
    for (let index = 0; index < this.items.length; index++) {
      const element = this.items[index];
      if (element.isOpen) {
        return true;
      }
    }
    return false;
  }

  findMenuElement(){
    var element = html_functions.findElementByClass(`button-container-desktop`);
    return element;
  }

  hideMenu(){
    var menu = this.findMenuElement();
    html_functions.hideElement(menu);
  }

  showMenu(){
    var menu = this.findMenuElement();
    html_functions.showElement(menu);
  }

  setModalContext(modal_context) {
    this.modal_context=modal_context;
    this.items.forEach((element)=>{
      element.setModalContext(modal_context);
    });
  }
}
