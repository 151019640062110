/*
 *  SOL_Options.mjs
 *  Solitaire
 *
 *  Created by Kim Moran on Sept 14, 2022
 *  Copyright © 2022 Sinclair Digital. - All Rights Reserved
 *
 *  Player options, shared by client and server.
 *
 * // NOTES
 * Anything the player can customize will go here so the players' options can be restored.
 * Ask Chris about textually storing card-art vs enumeration.
/* ---------------------------------------------------------------- */

// Preferred suit
// Volume
// Sound Effects
// Type - 1 card/3card vegas rules (you only get to go through stock one time!)
// Stock/waste on left/right hand side.
// Background art choices
// Card-art choices - textually stored, not enumerated
// Animation on/off
// Talking cards on/off

export function SOL_Options(SOL_Object) {

  const GAMEMODE = SOL_Object.client.GAMEMODE;

  // game change mode which triggers a new game, options menu close, and nav reset.

  this.gameMode = function () {
    let easy = document.querySelector('.easy-difficulty-card');
    let hard = document.querySelector('.hard-difficulty-card');

    if(easy.classList.contains('active-card')){
      return GAMEMODE.STANDARD;
    } else if(hard.classList.contains('active-card')){
      return GAMEMODE.KLONDIKE;
    }

    return GAMEMODE.STANDARD;
  };

  // this.sound = function() {
  //     return document.querySelector("#sound").checked ? 1 : 0
  // }

  // this.deckChoice = function (deck, device) {

  //   let desktopCard = document.querySelector('.desktop-card');
  //   let mobileCard = document.querySelector('.mobile-card');
  //   let accessibilityCard = document.querySelector('.accessibility-card');
  //   let accessibility1Card = document.querySelector('.accessibility1-card');
  //   let desktopImage = document.querySelector('.desktop-image');
  //   let mobileImage = document.querySelector('.mobile-image');
  //   let accessibilityImage = document.querySelector('.accessibility-image');
  //   let accessibility1Image = document.querySelector('.accessibility1-image');


  //   // quick initial check to show the default deck for player device.
  //   if (device == 'mobile') {
  //     mobileCard.style.display = 'flex';
  //     desktopCard.style.display = 'none';
  //   } else if (device == 'desktop') {
  //     mobileCard.style.display = 'none';
  //     desktopCard.style.display = '';
  //   }

  //   // now determine if the player wants accessibility options vs desktop and style according to active and inactive classes
  //   if (deck == 'desktop') {

  //     desktopCard.classList.add('active-card-boxes');
  //     desktopCard.classList.remove('inactive-card-boxes');
  //     desktopImage.classList.add('active-card-images');
  //     desktopImage.classList.remove('inactive-card-images');

  //     mobileCard.classList.remove('active-card-boxes');
  //     mobileCard.classList.add('inactive-card-boxes');
  //     mobileImage.classList.remove('active-card-images');
  //     mobileImage.classList.add('inactive-card-images');

  //     accessibilityCard.classList.remove('active-card-boxes');
  //     accessibilityCard.classList.add('inactive-card-boxes');
  //     accessibilityImage.classList.remove('active-card-images');
  //     accessibilityImage.classList.add('inactive-card-images');

  //     accessibility1Card.classList.remove('active-card-boxes');
  //     accessibility1Card.classList.add('inactive-card-boxes');
  //     accessibility1Image.classList.remove('active-card-images');
  //     accessibility1Image.classList.add('inactive-card-images');

  //   } else if (deck == 'mobile') {
  //     // add active styling to mobile. Remove all others.

  //     mobileCard.classList.add('active-card-boxes');
  //     mobileCard.classList.remove('inactive-card-boxes');
  //     mobileImage.classList.add('active-card-images');
  //     mobileImage.classList.remove('inactive-card-images');

  //     desktopCard.classList.remove('active-card-boxes');
  //     desktopCard.classList.add('inactive-card-boxes');
  //     desktopImage.classList.remove('active-card-images');
  //     desktopImage.classList.add('inactive-card-images');

  //     accessibilityCard.classList.remove('active-card-boxes');
  //     accessibilityCard.classList.add('inactive-card-boxes');
  //     accessibilityImage.classList.remove('active-card-images');
  //     accessibilityImage.classList.add('inactive-card-images');

  //     accessibility1Card.classList.remove('active-card-boxes');
  //     accessibility1Card.classList.add('inactive-card-boxes');
  //     accessibility1Image.classList.remove('active-card-images');
  //     accessibility1Image.classList.add('inactive-card-images');
  //   } else if (deck == 'accessibility') {

  //     // add active styling to accessibility. Remove all others.
  //     accessibilityCard.classList.add('active-card-boxes');
  //     accessibilityCard.classList.remove('inactive-card-boxes');
  //     accessibilityImage.classList.add('active-card-images');
  //     accessibilityImage.classList.remove('inactive-card-images');

  //     mobileCard.classList.remove('active-card-boxes');
  //     mobileCard.classList.add('inactive-card-boxes');
  //     mobileImage.classList.remove('active-card-images');
  //     mobileImage.classList.add('inactive-card-images');

  //     desktopCard.classList.remove('active-card-boxes');
  //     desktopCard.classList.add('inactive-card-boxes');
  //     desktopImage.classList.remove('active-card-images');
  //     desktopImage.classList.add('inactive-card-images');

  //     accessibility1Card.classList.remove('active-card-boxes');
  //     accessibility1Card.classList.add('inactive-card-boxes');
  //     accessibility1Image.classList.remove('active-card-images');
  //     accessibility1Image.classList.add('inactive-card-images');
  //   } else if (deck == 'accessibility1') {

  //     // add active styling to accessibility1. Remove all others.

  //     accessibility1Card.classList.add('active-card-boxes');
  //     accessibility1Card.classList.remove('inactive-card-boxes');
  //     accessibility1Image.classList.add('active-card-images');
  //     accessibility1Image.classList.remove('inactive-card-images');

  //     mobileCard.classList.remove('active-card-boxes');
  //     mobileCard.classList.add('inactive-card-boxes');
  //     mobileImage.classList.remove('active-card-images');
  //     mobileImage.classList.add('inactive-card-images');

  //     desktopCard.classList.remove('active-card-boxes');
  //     desktopCard.classList.add('inactive-card-boxes');
  //     desktopImage.classList.remove('active-card-images');
  //     desktopImage.classList.add('inactive-card-images');

  //     accessibilityCard.classList.remove('active-card-boxes');
  //     accessibilityCard.classList.add('inactive-card-boxes');
  //     accessibilityImage.classList.remove('active-card-images');
  //     accessibilityImage.classList.add('inactive-card-images');
  //   }
  // };

}
